@font-face {
  font-family: 'ROsanswebtext';
  src: url('./fonts/ROsanswebtextregular.eot');
  src: url('./fonts/ROsanswebtextregular.woff') format('woff'), url('./fonts/ROsanswebtextregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ROsanswebtext';
  src: url('./fonts/ROsanswebtextbold.eot');
  src: url('./fonts/ROsanswebtextbold.woff') format('woff'), url('./fonts/ROsanswebtextbold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'ROsanswebtext';
  src: url('./fonts/ROsanswebtextitalic.eot');
  src: url('./fonts/ROsanswebtextitalic.woff') format('woff'), url('./fonts/ROsanswebtextitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ROserifweb';
  src: url('./fonts/ROserifwebregular.eot');
  src: url('./fonts/ROserifwebregular.woff') format('woff'), url('./fonts/ROserifwebregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ROserifweb';
  src: url('./fonts/ROserifwebitalic.eot');
  src: url('./fonts/ROserifwebitalic.woff') format('woff'), url('./fonts/ROserifwebitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ROserifweb';
  src: url('./fonts/ROserifwebbold.eot');
  src: url('./fonts/ROserifwebbold.woff') format('woff'), url('./fonts/ROserifwebbold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}
